
async function digestClientId(id) {
  const encodedClientId = new TextEncoder('utf-8').encode(id);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", encodedClientId);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join('');
}

/**
 * Returns the Client ID, given the access token.
 * @param {String} accessToken 
 * @returns {Promise<String>} - a promise that resolves to a valid Client ID or 'NOT_SET'
 */
export async function getClientId(accessToken) {
  if (!accessToken) {
    return 'NOT_SET';
  }
  
  const base64Url = accessToken.split('.')[1];
  const jsonPayload = Autodesk.Viewing.fromUrlSafeBase64(base64Url);
  const json = JSON.parse(jsonPayload);

  // only accept hashed client_id
  const clientId = json.client_id;
  if (clientId) {
    const maskedClientId = await digestClientId(clientId);
    return maskedClientId;
  }

  return 'NOT_SET';
};